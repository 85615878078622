import styled from 'styled-components/macro'

import {styleGuide} from '@infomaker/style-guide'

const fontFamily = styleGuide.font.family      // Roboto
const fontSize = styleGuide.font.size          // 100%             -- 16px in all modern browsers
const fontWeight = styleGuide.font.weight      // 400              -- normal
const lineHeight = styleGuide.font.lineHeight  // 1.375            -- 22px
const color = styleGuide.font.color            // colors.gray(900) -- #191919

const brandDark = styleGuide.colors.brand(900)
const brandLight = styleGuide.colors.brand(100)
const blackTransparent = styleGuide.colors.blackTransparent(0.1)

const AppPage = styled.div`
    display: flex;
    justify-content: center;  
    flex: 1 0 auto;
`
const AppWrapper = styled.div`
    width: 900px;
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-bottom: 20px;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
    @media (max-width: 766px) {
        width: 300px;
    } 
`
const HeaderWrapper = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @media (max-width: 766px) {
        flex-direction: column-reverse;
        padding: 20px;
    } 
`
const HeaderContent = styled.div`
    width: 900px;
    position: relative;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
    @media (max-width: 766px) {
        width: 300px;
    } 
`
const HeaderImage = styled.div`
    position: absolute;
    top: 43px;
    right: 2px;
    @media (max-width: 766px) {
        display: none;
    }
`
const LogoImage = styled.img`
    width: 145px; 
    height: 35px;
    object-fit: contain;
`
const Title = styled.h1`
	color: ${color};
	font-size: 150%;
	font-weight: bold;
	line-height: 30px;
    font-family: ${fontFamily};
    letter-spacing: 0;
`
const HeaderTitle = styled.h1`
	color: ${color};
	font-size: 150%;
	font-weight: bold;
	line-height: 30px;
    font-family: ${fontFamily};
    margin-block-start: 43px;
`
const UpdateText = styled.p`
    font-size: 87.5%;
    font-weight: ${fontWeight}; 
    line-height: ${lineHeight};
    color: ${styleGuide.colors.gray(500)};
    font-family: ${fontFamily};
    font-style: ${fontWeight};
    align-self: flex-center;
    margin-left: 20px;
`
const ConfigTool = styled.div`
    font-style: italic;
    font-size: 87.5%;
    font-weight: ${fontWeight}; 
    line-height: ${lineHeight};
    color: ${color};
    font-family: ${fontFamily};
    align-self: flex-start;
    margin-left: 20px;
    a {
    text-decoration: none;
    }
    @media (min-width: 767px) and (max-width: 980px) {
        margin-left: 50px;
    } 
    @media (max-width: 766px) {
        display: none;
    }
`
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Footer = styled.div`
    width: 100%;
    height: 92px;
    background-color: ${brandDark};
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-shrink: 0;
`
const FooterBackground = styled.div`
    width: 900px;
    height: 92px;
    position: relative;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
    @media (max-width: 766px) {
        width: 300px;
    } 
`
const FooterImage = styled.div`
    position: absolute;
    top: 30px;
    left: 2px;
`
const NoMatchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    background-color: ${blackTransparent};
    padding: 10px;
`
const Code = styled.span`
    font-size: ${fontSize};
    display: block;
    font-size: 81.3%;
    color: black;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    background-color: ${brandLight};
    text-align: center;
    width: fit-content;
    padding: 2px 1px; 
    margin: 5px 0;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`
export {
    color,
    AppPage,
    ConfigTool,
    UpdateText,
    AppWrapper,
    HeaderWrapper,
    HeaderContent,
    HeaderImage,
    HeaderTitle,
    LogoImage,
    Title,
    ListWrapper,
    Footer,
    FooterBackground,
    FooterImage,
    Code,
    NoMatchWrapper
}