import marked from 'marked'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'

hljs.registerLanguage('javascript', javascript)
marked.setOptions({
    renderer: new marked.Renderer(),
    highlight: function(code) {
        return hljs.highlightAuto(code).value
    },
    gfm: true,
    tables: true,
    breaks: true,
    xhtml: false
})

const printMarkedAndHighlighted = (string) => {
    return marked(string)
}

export default printMarkedAndHighlighted