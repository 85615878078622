import React from 'react'

import printMarkedAndHighlighted from '../utils/printMarkedAndHighlighted'

import './Markdown.css'
import 'highlight.js/styles/vs.css'

import {
    HighlightedDependency,
} from './ListItemStyle'

import {
    TabText,
    PluginDetailsHeader
} from './DetailsStyle'

const DetailsContent = (({plugin, tab = 'readme'}) => {

    const renderReadmeMarkdown = () => {

        const {readme, version, pluginJsonPath} = plugin
        const pluginBasePath = pluginJsonPath.replace('/plugin.json', '')

        const readmeHtml = printMarkedAndHighlighted(
            readme.replace(/{PLUGIN_VERSION}/g, version)
                .replace(/{version}/g, version)
        )

        // Replaces any images with absolute src paths, i.e. NOT starting with http
        return readmeHtml.replace(/img src="(?!https?:\/\/)(?<imgsrc>.*)"/g, `img src="https://plugins.writer.infomaker.io/${pluginBasePath}/$<imgsrc>"`)
    }

    return (
        <div>

            {(tab === 'readme' && plugin.readme) &&
                <div className="markdown-body" dangerouslySetInnerHTML={{
                    __html: renderReadmeMarkdown()
                }}></div>
            }

            {(tab === 'changelog' && plugin.changelog) &&
                <div className="markdown-body" dangerouslySetInnerHTML={{
                    __html: printMarkedAndHighlighted(plugin.changelog)
                }}></div>
            }

            {(tab === 'dependencies' && plugin.manifest) &&
                <div className="manifest-tab" >

                    <PluginDetailsHeader>Dependencies and Version-info</PluginDetailsHeader>
                    <div className="markdown-body">

                        {(plugin ?? '') && Object.keys(plugin.manifest.dependencies).map(dependency =>
                            <ul key={dependency}> <li>{dependency} dependency: <HighlightedDependency>{plugin.manifest.dependencies[dependency]}</HighlightedDependency> </li></ul>
                        )}

                        <TabText>Example how to interpret which version that is required, according to Semver (<a href="https://semver.org/">Semver org</a>):
                                    <ul>
                                <li>^1.2.3 means "is greater than 1.2.3  but lower than 2.0.0."
                                    </li>
                                <li>^0.2.3	means "is greater than 0.2.3  but lower than 0.3.0."
                                    </li>
                                <li>^0.0.1	means "is = 0.0.1."
                                    </li>
                                <li>0.14.x || 15.x.x means "version 0.14.x or 15.x.x".
                                    </li>
                            </ul>

                        </TabText>
                    </div>
                </div>}
        </div>
    )
})

export default DetailsContent
