import styled from 'styled-components/macro'
import {styleGuide} from '@infomaker/style-guide'

const brand = styleGuide.colors.gray(500)

const NoResultWrapper = styled.div`
    color: ${brand};
    padding: 25px 0px;
    font-style: italic;
    flex: 1;  
    font-weight: bold;
`

export {NoResultWrapper}