import React, {useEffect, useState} from 'react'

import {satisfies} from 'semver'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'

import readableDate from './utils/readableDate'
import ListComponent from './components/ListComponent'
import DetailsComponent from './components/DetailsComponent'
import {
    AppWrapper,
    HeaderWrapper,
    HeaderContent,
    HeaderImage,
    HeaderTitle,
    LogoImage,
    AppPage,
    FooterBackground,
    Footer,
    FooterImage
} from './AppStyle'

import logo from './assets/logo.png'
import logo_Light from './assets/logo_Light.png'

export const App = () => {

    const [listCreated, setListCreated] = useState('')
    const [plugins, setPlugins] = useState([])
    const [releasedPlugins, setReleasedPlugins] = useState([])
    const [filteredPlugins, setFilteredPlugins] = useState([])
    const [filter, setFilter] = useState('')

    useEffect(() => {

        const loadPlugins = async () => {

            const data = await fetch(`${window.pluginJsonRoot}/index.json`)
            const json = await data.json()

            json.plugins.forEach(plugin => {
                plugin.latest = plugin.versions?.latest ?? false
            })

            const releasedAndPublicPlugins = json.plugins.map((plugin) => {

                return {
                    ...plugin,
                    versions: Object.fromEntries(
                        Object.entries(plugin.versions)
                            .filter(([version]) => satisfies(version, '>=1.*.*'))
                    )
                }
            }).filter(plugin => {
                return Boolean(plugin?.latest?.private) === false && Boolean(plugin.versions && Object.keys(plugin.versions).length)
            })

            setListCreated(json.listCreated)
            setPlugins(json.plugins)
            setReleasedPlugins(releasedAndPublicPlugins)
            setFilteredPlugins(releasedAndPublicPlugins)
        }

        loadPlugins()

    }, [])

    const handleChange = (value = '') => {
        // const {value} = event?.target ?? false

        if (value && value.length) {
            const filteredPlugins = releasedPlugins.filter(plugin => {

                return (
                    plugin.latest.label.toLowerCase().search(value.toLowerCase()) !== -1 ||
                    plugin.latest.name.toLowerCase().search(value.toLowerCase()) !== -1 ||
                    plugin.latest.description.toLowerCase().search(value.toLowerCase()) !== -1
                )
            })

            setFilter(value)
            setFilteredPlugins(filteredPlugins)

        }
        else {
            setFilter(value)
            setFilteredPlugins(releasedPlugins)
        }
    }

    return (
        <Router>
            <HeaderWrapper>
                <HeaderContent>
                    <HeaderTitle>Naviga Writer plugins</HeaderTitle>
                    <HeaderImage><LogoImage src={logo} alt='naviga with logotype'></LogoImage></HeaderImage>
                </HeaderContent>
            </HeaderWrapper>
            <AppPage>
                <AppWrapper>
                    {plugins.length > 0 && (
                        <Switch>
                            <Route path='/:pluginName/:version/:tab?' children={<DetailsComponent plugins={plugins} />} />
                            <Route path='/*' children={<ListComponent plugins={filteredPlugins} filter={filter} handleChange={handleChange} createdDate={readableDate(listCreated)} />} />
                        </Switch>
                    )}
                </AppWrapper>
            </AppPage>
            <Footer>
                <FooterBackground>
                    <FooterImage><LogoImage src={logo_Light} alt='naviga with logotype'></LogoImage></FooterImage>
                </FooterBackground>
            </Footer>
        </Router >
    )
}
