import {React, useCallback, useRef, useEffect} from 'react'
import {Input, IconButton} from '@infomaker/style-guide'


import {
    FilterWrapper,
    FilterInputs,
    ClearIcon,
    ShortcutWrapper,
    Shortcut,
    ClearIconWrapper

} from './FilterComponentStyle'

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => htmlElRef.current && htmlElRef.current.focus()

    return [htmlElRef, setFocus]
}

const FilterComponent = ({handleChange, filter}) => {
    const [searchRef, setSearchFocus] = useFocus()

    const isMac = navigator.appVersion.indexOf('Mac') !== -1

    const handleKeyPress = useCallback((event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            setSearchFocus()

        }
    }, [setSearchFocus]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleKeyDown = (event) => {
        const {keyCode} = event

        // Clear filter on "Escape"
        if (keyCode === 27) {
            handleChange()
        }
    }

    return (
        <FilterWrapper>
            <FilterInputs>
                <Input
                    value={filter}
                    onValueChange={handleChange}
                    onKeyDown={handleKeyDown}
                    variant=''
                    size=''
                    display='inlineblock'
                    width='100%'
                    label=''
                    placeholder='Search and filter'
                    icon={
                        {
                            name: 'search',
                            weight: 'bold',
                            rotate: ''
                        }
                    }
                    autoComplete='off'
                    autoFocus
                    ref={searchRef}
                />
                <ShortcutWrapper filter={filter}>
                    <Shortcut>{isMac ? '⌘' : 'CTRL'}+K</Shortcut>
                </ShortcutWrapper>
                {filter && (
                    <ClearIconWrapper>
                        <ClearIcon onClick={() => {handleChange()}}>
                            <IconButton
                                icon={{
                                    name: 'remove',
                                    weight: 'bold',
                                    height: undefined,
                                    width: undefined
                                }}
                                size=''
                                variant=''
                            />
                        </ClearIcon>
                    </ClearIconWrapper>
                )}
            </FilterInputs>
        </FilterWrapper>
    )
}

export default FilterComponent