import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {
    Title,
    UpdateText,
    NoMatchWrapper,
    Code
} from '../AppStyle'

const NoMatchComponent = (({plugin}) => {
    const location = useLocation()
    const {pluginName, version} = useParams()

    return (
        <NoMatchWrapper>
            {plugin && plugin.version ?
                <div>
                    <Title>
                        Could not load plugin information.
                    </Title>
                    <UpdateText>
                        This usually happens when the plugin hasn't had a new release recently.
                    </UpdateText>
                </div> :
                <div>
                    <Title>
                        Could not find plugin
                    </Title>
                    <UpdateText>
                        Plugin: {pluginName} and/or {version} does not seem to exist.
                    </UpdateText>
                    <UpdateText>
                        Make sure to check your URL <Code>{location.pathname}</Code>
                    </UpdateText>
                </div>
            }
        </NoMatchWrapper>
    )
})

export default NoMatchComponent