import React from 'react'
import {useHistory} from 'react-router-dom'

import {
    Text,
    PluginItemTitle,
    PluginItem,
    PluginItemWrapper,
    WrapperShadow,
    PluginItemSymbol,
    PluginItemSymbolText,
    PluginVersionPill
} from './ListItemStyle'

import printMarkedAndHighlighted from '../utils/printMarkedAndHighlighted'
import uppercaseFirst from '../utils/uppercaseFirst'

const ListItemComponent = ({filter, plugin}) => {

    const history = useHistory()

    let label = plugin.latest.label
    let description = printMarkedAndHighlighted(plugin.latest.description)

    filter = filter.replace('im-', '')

    if (filter && filter.length > 1) {
        label = label.replace(uppercaseFirst(filter), `<span class="highlight-filter">${uppercaseFirst(filter)}</span>`)
        label = label.replace(filter, `<span class="highlight-filter">${filter}</span>`)

        description = description.replace(uppercaseFirst(filter), `<span class="highlight-filter">${uppercaseFirst(filter)}</span>`)
        description = description.replace(filter.toLowerCase(), `<span class="highlight-filter">${filter.toLowerCase()}</span>`)
    }

    const handleClick = () => {
        history.push(`/${plugin.name}/${plugin.latest.version}`)
    }

    return (
        <WrapperShadow>
            <PluginItemWrapper type='button' onClick={handleClick}>
                <PluginItemSymbol>
                    <PluginItemSymbolText>{plugin.latest.label}</PluginItemSymbolText>
                </PluginItemSymbol>
                <PluginItem>
                    <PluginItemTitle dangerouslySetInnerHTML={{__html: label}}></PluginItemTitle>
                    <Text dangerouslySetInnerHTML={{__html: description}}></Text>
                </PluginItem>
                <PluginVersionPill>Latest version: {plugin.latest.version}</PluginVersionPill>
            </PluginItemWrapper>
        </WrapperShadow>
    )
}

export default ListItemComponent
