import React from 'react'

import {Button} from '@infomaker/style-guide'
import {TabWrapper} from './DetailsStyle'


const DetailsTabComponent = ({setActiveTab, activeTab}) => {

    const handleChange = (newValue) => {
        if (['readme', 'changelog', 'dependencies'].includes(newValue) && newValue !== activeTab) {
            setActiveTab(newValue)
        }
    }

    return (
        <TabWrapper>
            <Button
                variant={'tab'}
                selected={activeTab === 'readme'}
                onClick={() => handleChange('readme')}
            >Info</Button>
            <Button
                variant={'tab'}
                selected={activeTab === 'changelog'}
                onClick={() => handleChange('changelog')}
            >Changelog</Button>
            <Button
                variant={'tab'}
                selected={activeTab === 'dependencies'}
                onClick={() => handleChange('dependencies')}
            >Dependencies</Button>
        </TabWrapper>
    )
}

export default DetailsTabComponent
