
import styled from 'styled-components/macro'

import {styleGuide} from '@infomaker/style-guide'
const fontFamily = styleGuide.font.family      // Roboto
const fontSize = styleGuide.font.size          // 100%             -- 16px in all modern browsers
const fontWeight = styleGuide.font.weight      // 400              -- normal
const lineHeight = styleGuide.font.lineHeight  // 1.375            -- 22px
const color = styleGuide.font.color            // colors.gray(900) -- #191919

const PluginDetailsCover = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 900px;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    }
    @media (max-width: 766px) {
        width: 300px;
    } 
`
const PluginDetailsWrapper = styled.div`
    display: flex;
    width: 900px;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 21px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px 0px inset;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
    @media (max-width: 766px) {
        width: 300px;
        flex-direction: column;
        padding: 21px 15px;
    } 
`

const TabWrapper = styled.div`
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
     width: 100%;
     margin: 18px 0px 38px 0px;
     box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
     button {
         cursor: pointer;
     }
     @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
     @media (max-width: 766px) {
         width: 300px;
         button { 
             padding-right: 12px; 
             padding-left: 12px;
         }
     }  
 `
const TabText = styled.div`
    font-size: ${fontSize};
    font-weight: ${fontWeight}; 
    line-height: ${lineHeight};
    color: ${color};
    font-family: ${fontFamily};
    margin: 5px 0;
    padding: 5px 0;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
    a {
    text-decoration: none;
    }
`
const PluginDetailsHeader = styled.h1`
    color: ${color};
	font-size: 112.5%;
	font-weight: bold;
	line-height: 30px;
    font-family: ${fontFamily};
    letter-spacing: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaecef;
`
const PluginDetailsInfo = styled.div`
    flex-grow: 1;
    width: 900px;
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    }
    @media (max-width: 766px) {
        width: 300px;
    } 
    
`
const LoadingIcon = styled.div`
    text-align: center;
    height: 60px;
    width: 60px;
    margin: 50px auto;
    margin-bottom: 80px;
    color: ${color};
    animation: spin 3s linear infinite;
        @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
const PluginDetailsClose = styled.div`
    position: absolute;
    top: -42px;
    left: -30px;
    @media (max-width: 400px)  {
        top: -62px;
    } 
`

export {
    color,
    PluginDetailsCover,
    TabWrapper,
    TabText,
    PluginDetailsWrapper,
    PluginDetailsInfo,
    LoadingIcon,
    PluginDetailsClose,
    PluginDetailsHeader
}
