import React, {useState, useEffect} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'
import {Icon} from '@infomaker/style-guide'

import readableDate from '../utils/readableDate'
import NoMatchComponent from './NoMatchComponent'

import DetailsTabComponent from './DetailsTabComponent'
import VersionSelectComponent from './VersionSelectComponent'

import './Markdown.css'
import 'highlight.js/styles/vs.css'

import {
    PluginItemTitle,
    PluginItemDependency,
    HighlightedDependency,
    PluginItem,
    PluginItemSymbol,
    PluginItemSymbolText,
    color
} from './ListItemStyle'

import {
    PluginDetailsCover,
    LoadingIcon,
    PluginDetailsWrapper,
    PluginDetailsInfo,
    PluginDetailsClose
} from './DetailsStyle'

import DetailsContent from './DetailsContent'

const PluginDetailsComponent = (({plugins = []}) => {

    const {pluginName, version, tab: activeTab = 'readme'} = useParams()

    const [activePlugin, setActivePlugin] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const allVersions = Object.keys(plugins.find(({name}) => name === pluginName)?.versions ?? {})
    const plugin = plugins.find(i => i.name === pluginName)

    useEffect(() => {

        const fetchPluginDetails = async () => {

            const pluginVersion = plugin?.versions[version]

            if (plugin === undefined || Boolean(pluginVersion) === false) {
                setLoading(false)
                setError(true)

                return
            }

            let {pluginJsonPath} = pluginVersion

            if (!pluginJsonPath.startsWith('/')) {
                pluginJsonPath = `/${pluginJsonPath}`
            }

            const data = await fetch(`${window.pluginJsonRoot}${pluginJsonPath}`)

            if (data.ok) {
                const extendedPluginInfo = await data.json()

                setActivePlugin({
                    ...pluginVersion,
                    ...extendedPluginInfo
                })
            } else {
                setError(true)
                setActivePlugin(pluginVersion)
            }

            setLoading(false)
        }

        fetchPluginDetails()

        // Reset state on unmount
        return () => {
            setLoading(true)
            setError(false)
            setActivePlugin({})
        }
    }, [pluginName, version, plugin])

    const history = useHistory()
    const handleChangedTab = (newTab) => {
        history.push(`/${pluginName}/${version}/${newTab}`)
    }

    return (
        <PluginDetailsCover>
            <PluginDetailsClose>
                <Link to="/">
                    <Icon
                        name='arrow-left'
                        weight='bold'
                        color={color}
                        width={('width', 22)}
                        height={('height', 20)}
                        rotate='' />
                </Link>
            </PluginDetailsClose>
            <PluginDetailsInfo>
                {
                    loading ? <LoadingIcon><Icon
                        size="large"
                        name="loading-circle-rotate"
                        showSpinner={true}
                        color={color}
                        width={('width', 60)}
                        height={('height', 60)}
                        weight="light"
                    /></LoadingIcon> :

                        <div>
                            <PluginDetailsWrapper>

                                <PluginItemSymbol>
                                    <PluginItemSymbolText>{activePlugin?.label ?? ''}</PluginItemSymbolText>
                                </PluginItemSymbol>
                                <PluginItem>
                                    <PluginItemTitle>{activePlugin?.label ?? ''}</PluginItemTitle>

                                    {activePlugin?.private && (<p><b>This version of plugin {activePlugin?.name ?? ''} has not been made publicly available!</b></p>)}

                                    <PluginItemDependency>Writer dependency:
                                        {activePlugin.manifest && <HighlightedDependency>{activePlugin.manifest.dependencies.writer}</HighlightedDependency>}
                                    </PluginItemDependency>
                                    <PluginItemDependency>Release date: <HighlightedDependency>{readableDate(activePlugin.createdAt) ?? ''}</HighlightedDependency></PluginItemDependency>
                                </PluginItem>

                                <VersionSelectComponent versions={allVersions} />

                            </PluginDetailsWrapper>

                            <DetailsTabComponent activeTab={activeTab} setActiveTab={handleChangedTab} />

                            {error && <NoMatchComponent plugin={plugin} />}
                            {activePlugin && (
                                <DetailsContent plugin={activePlugin} tab={activeTab}></DetailsContent>
                            )}

                        </div>
                }
            </PluginDetailsInfo>
        </PluginDetailsCover >
    )
})

export default PluginDetailsComponent
