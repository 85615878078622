import React from 'react'
import ListItemComponent from './ListItemComponent'
import FilterComponent from './FilterComponent'

import {
    UpdateText,
    ListWrapper
} from '../AppStyle'
import {NoResultWrapper} from './ListComponentStyle'

const ListComponent = ({filter = '', plugins = [], handleChange, createdDate}) => {

    return (
        <ListWrapper>

            <div>
                <FilterComponent filter={filter} handleChange={handleChange} />
            </div>
            {plugins.length > 0 ? (
                <div>
                    {plugins.map(plugin => (<ListItemComponent key={plugin.name} plugin={plugin} filter={filter} />))}
                </div>
            ) : (
                <NoResultWrapper>
                    <div>No matching results</div>
                </NoResultWrapper>
            )}

            <UpdateText>The plugin documentation was generated: {createdDate}</UpdateText>

        </ListWrapper>
    )
}

export default ListComponent