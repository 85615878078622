import styled from 'styled-components/macro'

import {styleGuide} from '@infomaker/style-guide'

const fontFamily = styleGuide.font.family      // Roboto
const fontSize = styleGuide.font.size          // 100%             -- 16px in all modern browsers
const fontWeight = styleGuide.font.weight      // 400              -- normal
const lineHeight = styleGuide.font.lineHeight  // 1.375            -- 22px
const color = styleGuide.font.color            // colors.gray(900) -- #191919

const brand = styleGuide.colors.brand(500)
const brandLight = styleGuide.colors.brand(100)
const blackTransparent = styleGuide.colors.blackTransparent(0.1)


const PluginItemWrapper = styled.div`
    width: 900px;
    min-height: 160px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;  
    padding: 21px 0px;
    &:hover { 
    box-shadow: none;
    }  
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    }  
    @media (max-width: 766px) {
        width: 300px;
    } 
`
const WrapperShadow = styled.div`
    width: 940px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 1px 0 0px rgba(0,0,0,0.1); 
    &:first-child {
        box-shadow: none;
    } 
    &:hover + & {
        box-shadow: none;
    }
    &:hover {
    background-color: ${blackTransparent};
    box-shadow: none;
    border-radius: 16px;
    cursor: pointer;
    }
    @media (min-width: 767px) and (max-width: 980px) {
        width: 740px;
    } 
    @media (max-width: 766px) {
        width: 330px;
    }
`
const PluginItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 700px;
    padding: 0px;
    margin: auto;
    margin-left: 37px;
    .highlight-filter {
        background-color: ${brandLight}
    }
    @media (max-width: 766px) {
        margin-left: 0px;  
    }
`
const PluginItemSymbol = styled.div`
    height: 108px;
    width: 108px;
    border-radius: 24px;
    background-color: ${brand};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    @media (max-width: 766px) {
        display: none;
    }
`
const PluginItemSymbolText = styled.div`
    width: 108px;
    display: inline-block;
    color: #FFFFFF;
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: 900;
    letter-spacing: -0.24px;
    line-height: 18px;
    text-align: center;
    overflow:hidden;
    text-overflow: ellipsis;
    padding: 0px 10px;
    user-select: none;
    @media (max-width: 766px) {
        display: none;
    }
`
const PluginItemTitle = styled.h1`
	color: ${color};
	font-size: 150%;
	font-weight: bold;
	line-height: 30px;
    font-family: ${fontFamily};
    letter-spacing: 0;
    margin-block-start: 0px;
    margin-block-end: 5px;
`
const PluginItemVersion = styled.h3`
	color: ${color};
	font-weight: bold;
	line-height: 30px;
    font-family: ${fontFamily};
    letter-spacing: 0;
    margin-block-start: 0px;
    margin-block-end: 5px;
`
const PluginItemDependency = styled.p`
    font-style: italic;
    font-family: ${fontFamily};
    font-size: ${fontSize};
	color: ${color};
	line-height: 20px;
    font-style: ${fontWeight};
    letter-spacing: 0;
    margin-block-start: 1px;
    margin-block-end: 1px;
    @media (max-width: 766px) {
        margin-block-end: 10px;
    }
`

const HighlightedDependency = styled.span`
    font-weight: bold;
    background-color: ${brandLight};
    margin-left: 5px;
    padding: 0px 4px;
`

const PluginVersionPill = styled.div`
    font-family: ${fontFamily};
    font-size: 75%;
    font-weight: 700;
    line-height: 1.16667;
    color: rgb(25, 25, 25);
    display: inline-block;
    min-height: 20px;
    min-width: 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: none;
    padding: 3px 6px;
    border-radius: 10px;

`
const Text = styled.p`
    font-size: 81.3%;
    font-weight: ${fontWeight}; 
    line-height: ${lineHeight};
    color: ${color};
    font-family: ${fontFamily};
    margin: 5px 0;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    a {
    text-decoration: none;
    }
`
const PluginList = styled.ul`
    list-style-type: none;
    margin-top: 0px;
    padding: 0px;
`
export {
    color,
    PluginItemTitle,
    PluginItemVersion,
    PluginItemDependency,
    HighlightedDependency,
    Text,
    PluginItem,
    PluginItemWrapper,
    WrapperShadow,
    PluginItemSymbol,
    PluginItemSymbolText,
    PluginVersionPill,
    PluginList
}
