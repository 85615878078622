import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import './index.css'
import {App} from './App'

window.pluginJsonRoot = window.location.origin.includes('.local.') || window.location.origin.includes('saas-test') ?
  'https://saas-test-v1.writer.infomaker.io' :
  'https://plugins.writer.infomaker.io'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
