import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import sortSemver from 'semver-sort'

import {Select, ListItem} from '@infomaker/style-guide'
import {satisfies} from 'semver'

const VersionSelectComponent = ({versions = []}) => {

    const options = sortSemver.desc(
        versions.filter((version) => satisfies(version, '>=1.*.*'))
    )

    const history = useHistory()
    const {pluginName, version, tab: locationTab} = useParams()

    const handleChange = (selectedVersion) => {
        if (selectedVersion !== version) {
            history.push(`/${pluginName}/${selectedVersion}${locationTab ? `/${locationTab}` : ''}`)
        }
    }

    const optionItems = options.map((option, idx) => {
        return (
            <ListItem
                key={idx}
                value={option}
                label={option}
                selected={option === version}
            />
        )
    })

    return (
        <div>

            <Select
                value={`Version: ${version}`}
                onChange={handleChange}
                variant=''
                size=''
                display=''
                width={('width', 180)}
                maxHeight={('maxHeight (of list)', 150)}
                placeholder={`Version: ${version}`}
                disabled={optionItems.length === 1}
                showFilter={false}
                filterLabel='Version'
            >
                {optionItems}
            </Select>

        </div>
    )
}

export default VersionSelectComponent
