import styled from 'styled-components/macro'

import {styleGuide} from '@infomaker/style-guide'

const color = styleGuide.font.color            // colors.gray(900) -- #191919

const FilterWrapper = styled.div`
    width: 900px;
    display: flex;
    position: relative;
    flex-direction: column; 
    @media (min-width: 767px) and (max-width: 980px) {
        width: 700px;
    } 
    @media (max-width: 766px) {
        width: 300px;
    }  
`
const FilterInputs = styled.div`
    margin: 15px 0px;
    flex: 1;  
`

const ClearIconWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ClearIcon = styled.div`
    cursor: pointer;
    
`

// < div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5" >
// <kbd class="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"> ⌘K </kbd>
// </ >
const ShortcutWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${props => props.filter === '' ? '15px' : '60px'};
    transition: right 0.2s;
`

const Shortcut = styled.div`
    border: 1px solid ${styleGuide.colors.gray(200)};
    border-radius: 4px;    
    color: ${styleGuide.colors.gray(500)};
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    padding: 4px;
`

export {
    color,
    FilterWrapper,
    FilterInputs,
    ClearIconWrapper,
    ClearIcon,
    ShortcutWrapper,
    Shortcut
}

